import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/Home/Home.vue");
const Platform = () => import("@/views/Platform/Platform.vue");
const Product = () => import("@/views/Product/Product.vue");
// views
const DiscoveryCX = () => import("@/views/DiscoveryCX/DiscoveryCX.vue")
const ChatCX = () => import("@/views/ChatCX/ChatCX.vue")

const Studio = () => import("@/views/Studio/Studio.vue");
const Solutions = () => import("@/views/Solutions/Solutions.vue");
const Company = () => import("@/views/Company/Company.vue");
const Demo = () => import("@/views/Demo/Demo.vue");

Vue.use(VueRouter);

const HTTPH = "wcx";

const routes = [
  {
    path: "/",
    redirect: `/wcx_web`,
    hidden: true,
    meta: {
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: `/wcx_web`,
    name: "Home",
    component: Home,
    meta: {
      name: "Home",
      id: 0,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: `/wcx_web/Platform`,
    name: "Platform",
    component: Platform,
    meta: {
      name: "PLATFORM",
      id: 1,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
    children:[
      {
        path: `/wcx_web/GenerativeAIDataExperiencePlatform`,
        name: "GenerativeAIDataExperiencePlatform",
        meta: {
          name: "Generative AI Data Experience Platform",
          id: 0,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/Integrations`,
        name: "Integrations",
        meta: {
          name: "Integrations",
          id: 1,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/CMS`,
        name: "CMS",
        meta: {
          name: "CMS",
          id: 2,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/WhiteLabelMobilePlatform`,
        name: "WhiteLabelMobilePlatform",
        meta: {
          name: "White Label Mobile Platform",
          id: 3,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
    ]
  },
  {
    path: `/wcx_web/Product`,
    name: "Product",
    component: Product,
    meta: {
      name: "PRODUCT",
      id: 2,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
    children: [
      {
        path: `/wcx_web/DiscoveryCX`,
        name: "DiscoveryCX",
        component: DiscoveryCX,
        meta: {
          name: "DiscoveryCX",
          id: 0,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/ChatCX`,
        name: "ChatCX",
        component: ChatCX,
        meta: {
          name: "ChatCX",
          id: 1,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/Co-PilotCX`,
        name: "Co-PilotCX",
        meta: {
          name: "Co-PilotCX",
          id: 2,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
    ]
  },
  {
    path: `/wcx_web/Studio`,
    name: "Studio",
    component: Studio,
    meta: {
      name: "STUDIO",
      id: 3,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: `/wcx_web/Solutions`,
    name: "Solutions",
    component: Solutions,
    meta: {
      name: "SOLUTIONS",
      id: 4,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
    children:[
      {
        path: `/wcx_web/RetailBrokerage`,
        name: "RetailBrokerage",
        meta: {
          name: "Retail Brokerage",
          id: 2,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/InvestorRelations`,
        name: "InvestorRelations",
        meta: {
          name: "Investor Relations",
          id: 1,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
    ]
  },
  {
    path: `/wcx_web/Company`,
    name: "Company",
    component: Company,
    meta: {
      name: "COMPANY",
      id: 5,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
    children:[
      {
        path: `/wcx_web/About`,
        name: "About",
        meta: {
          name: "About",
          id: 0,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/Partnerships`,
        name: "Partnerships",
        meta: {
          name: "Partnerships",
          id: 1,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/ContactUs`,
        name: "ContactUs",
        meta: {
          name: "Contact Us",
          id: 2,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
      {
        path: `/wcx_web/Careers`,
        name: "Careers",
        meta: {
          name: "Careers",
          id: 3,
          title: "",
          content: {
            keywords: "",
            description: "",
          },
        },
      },
    ]
  },
  {
    path: `/wcx_web/Demo`,
    name: "Demo",
    component: Demo,
    meta: {
      name: "DEMO",
      id: 6,
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
];

// 解决重复触发了同一个路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
