<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
  name: "App",
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.ListenIn();
      }, 10);
      if (this.$route.path == "/wcx_web/" || this.$route.path == "/wcx_web") {
        this.$router.push("/");
      }
    });
  },
  mounted() {},
  provide() {
    return {};
  },
  data() {
    return {};
  },
  watch: {
    $route: {
      handler: "routeCue",
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    routeCue: function (val, oldVal) {
      setTimeout(() => {
        this.ListenIn();
      }, 10);
    },
    ListenIn: function () {
      // 监听屏幕
      // Home
      let heroImgW = $("#Home")
        .find(".hero")
        .find(".heroBox")
        .find("img")
        .width();
      //Platform
      let PlatformheroImgW = $("#Platform")
        .find(".hero")
        .find(".heroBox")
        .find("img")
        .width();
      // Product
      let ProductheroImgW = $("#Product")
        .find(".hero")
        .find(".heroBox")
        .find("img")
        .width();
      // Studio
      let StudioheroImgW = $("#Studio")
        .find(".hero")
        .find(".heroBox")
        .find("img")
        .width();
      // Company
      let CompanyheroImgW = $("#Company")
        .find(".hero")
        .find(".heroBox")
        .find("img")
        .width();
      setTimeout(function () {
        // Home
        heroImgW = $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            bottom: heroImgW / -16,
          });
        $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": heroImgW / 17,
          });
        //Platform
        PlatformheroImgW = $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            top: PlatformheroImgW / 10.5,
          });
        $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": PlatformheroImgW / 17,
          });
        //Product
        ProductheroImgW = $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            top: ProductheroImgW / 10.5,
          });
        $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": ProductheroImgW / 17,
          });
        // Studio
        StudioheroImgW = $("#Studio")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Studio")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": StudioheroImgW / 17,
          });
        // Company
        CompanyheroImgW = $("#Company")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Company")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": CompanyheroImgW / 17,
          });
      }, 10);

      $(window).resize(() => {
        // Home
        heroImgW = $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            bottom: heroImgW / -16,
          });
        $("#Home")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": heroImgW / 17,
          });
        //Platform
        PlatformheroImgW = $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            top: PlatformheroImgW / 10.5,
          });
        $("#Platform")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": PlatformheroImgW / 17,
          });
        //Product
        ProductheroImgW = $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            top: ProductheroImgW / 10.5,
          });
        $("#Product")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": ProductheroImgW / 17,
          });
        // Studio
        StudioheroImgW = $("#Studio")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Studio")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": StudioheroImgW / 17,
          });
        // Company
        CompanyheroImgW = $("#Company")
          .find(".hero")
          .find(".heroBox")
          .find("img")
          .width();
        $("#Company")
          .find(".hero")
          .find(".heroBox")
          .find(".text")
          .css({
            "font-size": CompanyheroImgW / 17,
          });
      });
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
