export default {
    name: "Footer",
    created() {
        this.$nextTick(function () {

            // router.push({
            //     name: "Technical Chart",
            //     params: { ric: ric }
            // });
               // 获取导航栏
               $.each(this.$router.options.routes,  (index, routes) => {
                if (index >= 1) {
                    this.Nav.push(routes)
                }
            })

        });
    },
    mounted() {
        this.$nextTick(function () {

        })
    },
    data() {
        return {
            Nav: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    },
    components: {

    }
};