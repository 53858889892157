import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// 引入jquery
import $ from "jquery";

// 引入bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// 引入bootstrap的icon
import "bootstrap-icons/font/bootstrap-icons.css";

// 引入bootstrap-fileinput
import "@/assets/bootstrap-fileinput";
import "@/assets/bootstrap-fileinput/css/fileinput.min.css";
import "@/assets/bootstrap-fileinput/js/fileinput.min.js";

// 引入bootstrap-datetimepicker-master
import "@/assets/bootstrap-datetimepicker-master";
import "@/assets/bootstrap-datetimepicker-master/css/bootstrap-datetimepicker.min.css";
import "@/assets/bootstrap-datetimepicker-master/js/bootstrap-datetimepicker.min.js";

// 引入复制粘贴
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// 引入mux-player
import "@mux/mux-player";

//  引入 anychart
import anychart from "anychart";
Vue.prototype.$anychart = anychart;

// 懒加载、延迟加载
import VueLazyload from "vue-lazyload";

// facebook登录
import FBSignInButton from "vue-facebook-signin-button";
Vue.use(FBSignInButton);

// Google登录
import GLSignInButton from "vue-google-signin-button-directive";
Vue.use(GLSignInButton);

// 引入公共Css 解决被覆盖
// import Fonts from "./fonts.css";
import "./assets/css/main.css";

// 引入公共js
import global from "./assets/js/global.js";
Vue.prototype.$$ = global;

new Vue({
  router,
  store,
  $,
  render: (h) => h(App),
}).$mount("#app");
