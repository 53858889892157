export default {
    name: "Header",
    created() {
        this.$nextTick(function () {
            // 获取导航栏
            $.each(this.$router.options.routes,  (index, routes) => {
                if (index >= 1) {
                    this.Nav.push(routes)
                }
            })
        });
    },
    mounted() {
        this.$nextTick(function () {

        })
    },
    data() {
        return {
            Nav: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    },
    components: {

    }
};