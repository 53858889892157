

// 引入MUX
import mux from 'mux-embed';
export default {
    mux: mux,
    countries: require('../countries.json'),
    //   /**
    // * 将数值四舍五入后格式化.
    // * @param num 数值(Number或者String)
    // * @param cent 要保留的小数位(Number)
    // * @param isThousand 是否需要千分位 0:不需要,1:需要(数值类型);
    // * @return 格式的字符串,如'1,234,567.45'
    // * @type String
    // */
    formatNumber: function (num, cent, isThousand) {
        var sign, cents
        if (num == '-' || num == undefined) {
            return '-'
        }
        num = num.toString().replace(/\$|\,/g, '')
        // 检查传入数值为数值类型
        if (isNaN(num)) {
            num = '0'
        }
        // 获取符号(正/负数)
        sign = num == (num = Math.abs(num))
        num = Math.floor(num * Math.pow(10, cent) + 0.50000000001) // 把指定的小数位先转换成整数.多余的小数位四舍五入
        cents = num % Math.pow(10, cent) // 求出小数位数值
        num = Math.floor(num / Math.pow(10, cent)).toString() // 求出整数位数值
        cents = cents.toString() // 把小数位转换成字符串,以便求小数位长度
        // 补足小数位到指定的位数
        while (cents.length < cent) {
            cents = '0' + cents
        }
        if (isThousand) {
            // 对整数部分进行千分位格式化.
            for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
                num =
                    num.substring(0, num.length - (4 * i + 3)) +
                    ',' +
                    num.substring(num.length - (4 * i + 3))
            }
        }
        if (cent > 0) {
            return (sign ? '' : '-') + num + '.' + cents
        } else {
            return (sign ? '' : '-') + num
        }
    },
    // 转换时间
    datatimeFormatchangeToDateOnDashboard2: function (input) {
        var input_date = new Date(input);
        var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JUIY", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return `${input_date.getDate()} ${monthNames[input_date.getMonth()]} ${input_date.getFullYear()}`
    },
    datatimeFormatchangeToDateOnDashboard: function (input) {
        var input_date = new Date(input);
        var monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let d = input_date.getDate()
        if (d < 10) {
            d = `0${d}`
        }
        return `${input_date.getFullYear()}-${monthNames[input_date.getMonth()]}-${d}`
    },
    datatimeFormatchangeToDateOnDashboard1: function (input) {
        var input_date = new Date(input);
        var monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let d = input_date.getDate()
        if (d < 10) {
            d = `0${d}`
        }
        return `${input_date.getFullYear()}${monthNames[input_date.getMonth()]}${d}`
    },
    csvToArray: function (strData, strDelimiter) {
        // Check to see if the delimiter is defined. If not,then default to comma.
        // 检查是否定义了分隔符。如果未定义，则默认为逗号
        strDelimiter = (strDelimiter || ",");
        // Create a regular expression to parse the CSV values.
        // 创建一个正则表达式来解析CSV值。
        let objPattern = new RegExp((
            // Delimiters.(分隔符)
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
            // Quoted fields.(引用的字段)
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
            // Standard fields.(标准字段)
            "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        // Create an array to hold our data. Give the array a default empty first row.
        // 创建一个数组来保存数据。给数组赋值一个空元素数组
        let arrData = [
            []
        ];
        // Create an array to hold our individual pattern matching groups.
        // 创建一个数组来保存我们的单个模式匹配组
        let arrMatches = null;
        // Keep looping over the regular expression matches until we can no longer find a match.
        // 正则表达式匹配上保持循环,直到我们再也找不到匹配的
        while (arrMatches = objPattern.exec(strData)) {
            // Get the delimiter that was found.
            // 获取找到的分隔符
            let strMatchedDelimiter = arrMatches[1];
            // Check to see if the given delimiter has a length
            // 检查给定的分隔符是否有长度
            // (is not the start of string) and if it matches
            // （不是字符串的开头）如果匹配
            // field delimiter. If id does not, then we know
            // 字段分隔符。如果身份证没有，那我们就知道了
            // that this delimiter is a row delimiter.
            // 此分隔符是行分隔符。
            if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
                // Since we have reached a new row of data,add an empty row to our data array.
                // 既然我们得到了新的一行数据，向数据数组中添加一个空行。
                arrData.push([]);
            }
            // Now that we have our delimiter out of the way,
            // 既然我们已经把分隔符弄出来了，
            // let's check to see which kind of value we
            // 让我们来看看我们需要什么样的价值观
            // captured (quoted or unquoted).
            // 捕获（引用或未引用）。
            let strMatchedValue;
            if (arrMatches[2]) {
                // We found a quoted value. When we capture this value, unescape any double quotes.
                // 我们找到一个引用值。当我们抓到这个值，取消显示任何双引号
                strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"), "\"");
            } else {
                // We found a non-quoted value.
                // 我们找到了一个没有引号的值。
                strMatchedValue = arrMatches[3];
            }
            // Now that we have our value string, let's add it to the data array.
            // 现在我们有了值字符串，让我们将其添加到数据数组中
            arrData[arrData.length - 1].push(strMatchedValue);
        }
        // 移除最后一个空数据
        arrData.splice(-1);

        // Return the parsed data.
        // 返回解析结果
        return (arrData);
    },
    csvToObject: function (csv) {
        var array = this.csvToArray(csv);
        var objArray = [];
        for (var i = 1; i < array.length; i++) {
            objArray[i - 1] = {};
            for (var k = 0; k < array[0].length && k < array[i].length; k++) {
                var key = array[0][k];
                objArray[i - 1][key] = array[i][k]
            }
        }
        return objArray;
    }
};